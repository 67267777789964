import React from 'react';

const Maliha = () => {
    return (
        <div>
            <h1>Maliha you there .......</h1>
            {/* Content of the Maidha page goes here */}
        </div>
    );
};

export default Maliha;
