import React from "react";

function MainComponent() {
    // Replace with your actual data and import images as needed
    const profileData = {
        avatarUrl: process.env.PUBLIC_URL + '/IMGlow.jpg',
        name: "Zahid Uan Nabi",
        tagline: "Data Science & Big Data Analysis Enthusiast",
        bio: "Armed with a Bachelor's Degree in Computer Science and Engineering, I command expertise across Python, SQL, and cutting-edge analytics tools like Tableau, DAQ, D3 Notebook & Sagemaker. My realm extends from Backend Development to pioneering roles in Data Engineering, Data Mining, Predictive Analytics, Data Visualization, and Machine Learning. This isn't just proficiency; it's a robust command, a transformative capacity to morph complex data sets into striking narratives and decisive strategies. My background isn't just a skill set, but a testament to a powerhouse capable of steering the digital narrative through any challenge!",
        // ... additional profile data
    };
    return (
        <div className="App">
            <nav className="navbar">
                <ul>
                    <li><a href="#main" className="typing-effect"> {'< uannabi />'}</a></li>
                </ul>
            </nav>


            <div className="content">
                <div className="central-container">
                    <div className="left-column">
                        {/*<img src="/imglow.jpg" alt="Profile" className="profile-picture"/>*/}
                        <img src={profileData.avatarUrl} alt="Profile" className="profile-picture"/>
                        <h2>{profileData.name}</h2>
                        <p>{profileData.tagline}</p>
                        {/* ... other profile info ... */}

                    </div>
                    <div className="right-column">
                        <h1>Hey there, I'm a Python Engineer in Data and Analytics!</h1>
                        <p className="bio-text">{profileData.bio}</p>

                        <h2><strong>Experiences:</strong></h2>
                        <section className="experience">
                            <h2>Python Developer Data & Analytics</h2>
                            <h3>London Stock Exchange - UK</h3>
                            <p>April 2023 - Continue</p>
                            <p>

                                <strong>Responsibilities : </strong>

                                Led data migration initiatives, focusing on moving data from MongoDB sources.
                                Implemented intricate business logic transformations to tailor the data for target
                                destinations: Snowflake and PostgreSQL.
                                Utilized Dask atop FastAPI to orchestrate distributed processing without the overhead of
                                cluster management, fully harnessing Python's OOP capabilities.
                            </p>

                            <p>
                                <strong>Accomplishments : </strong>
                                Successfully integrated Dask, tapping into its PySpark-like distributed capabilities,
                                ensuring an optimized data migration process that's both scalable and efficient.
                                Pioneered the integration of FastAPI with Dask, blending the strengths of asynchronous
                                operations with distributed computing for robust data handling.
                            </p>
                            <p>
                                <strong>Challenges Faced : </strong>
                                The nature of the data, being stock-related, required meticulous attention to detail
                                during the cleaning and transformation phases. Ensured that no data was altered
                                unintentionally, maintaining the integrity and accuracy of every single value throughout
                                the migration process.
                            </p>
                            <p><strong>Tools & Stacks:</strong> Python, Dask, AWS, MongoDB, Snowflake, PostgreSQL</p>

                        </section>

                        <section className="experience">
                            <h2>Data Engineer (Blockchain)</h2>
                            <h3>Fasset - UK</h3>
                            <p>Dec 2021 - Feb 2023</p>
                            <p>I was responsible for designing, Maintaining, and developing end-to-end ETL and ELT
                                pipelines for Data Warehouse and Data Models on the top of Structural, Sami-structural &
                                Un-structural. According to business needs created multiple Automated Tableau dashboards
                                as well as ad-hoc reporting.</p>

                            <ul>
                                <li>Established Centralised data architecture for data warehousing which saved 48
                                    man-hours per week.
                                </li>
                                <li>By preparing the market-wise multiple automated Tableau Dashboards for multiple
                                    Stack holders, saved 60 man hours per week and increased company growth by 17%.
                                </li>
                                <li>Reduced 23% of server cost by optimising Data pipeline time on time.</li>
                            </ul>
                            <p><strong>Tools & Stacks:</strong> Python, Linux Tableau, ECS, ELK AWS Cloud9, AWS Glue,
                                Hadoop, Pyspark, Databricks Scala, ETL, ELT, RDBMS, NoSQL, AirFlow, Data Lake, and
                                Athena, ElasticeSearch </p>

                        </section>

                        <section className="experience">
                            <h2>Data Insights Managers</h2>
                            <h3>ADA-Asia - BD</h3>
                            <p>November 2019 - November 2021</p>
                            <p>I was responsible for maintenance, and enhance data warehouse in S3 Data lake.
                                Engaging with multiple steak holder and supporting them on an ad-hoc basis for data
                                analysis and Consumer profiling.</p>

                            <ul>
                                <li>Developed Customer Single View Data model. which reduced 4 man-hours and saved 40%
                                    of server cost.
                                </li>
                                <li>Automated Multi-purpose KPI measurement Dashboard for a client which saved 150
                                    man-hours per month.
                                </li>
                                <li>Improved accuracy by 2.3% on the Telco Churn Prediction model.</li>
                                <li>Enhanced accuracy by 1.7% on Credit Prediction model LR and LG-Boost Algorithm.</li>
                                <li>Increased Year on year Data efficiency by 150% and made it independent from regional
                                    shadow.
                                </li>
                                <li>Improved Year on year revenue by 15% by enriching data-driven product offerings.
                                </li>
                            </ul>
                            <p><strong>Tools & Stacks:</strong> Elastic Map Reduce, Redshift, Lambda, Databricks,
                                SageMaker, Hadoop, Hive,
                                PySpark, and Python. Media tools like google analytics, Facebook analytics, Tag Manager,
                                and
                                Facebook Audience Explorer.</p>

                        </section>
                        <section className="experience">
                            <h2>DevOps Engineer</h2>
                            <h3>Cookups Technology LTD - BD</h3>
                            <p>October 2018 - September 2019</p>
                            <p>I was responsible for maintaining Development, Staging, and Production Server. On the
                                basis of business need to create micro-services to automate or schedule tasks. Optimise
                                and review backend code.
                                Sole responsible for creating BI Dashboard using Metabase. </p>

                            <ul>
                                <li>
                                    Creating an automated Dynamic feed for Marketing campaigns Saved 40 man-hours per
                                    week.
                                </li>
                                <li>By using the BI database our business gains exponential growth on a weekly basis.
                                </li>
                                <li>By automating several services able to reduce 15% server cost on a monthly basis.
                                </li>
                                <li>Enhanced accuracy by 1.7% on Credit Prediction model LR and LG-Boost Algorithm.</li>

                            </ul>
                        </section>
                        <section className="experience">
                            <h2>Data Analyst</h2>
                            <h3>Adiva Graphics - BD</h3>
                            <p>February 2018 - November 2018</p>
                            <p>My key responsibility was to create a fully automated project management dashboard for
                                multiple steak holder.
                                Collaborate with different Data scientists to understand their needs from ETL to Data
                                visualization. Designing data pipelines, models, and visualizations that allow the
                                organization to understand its, business drivers.Performing cloud-based data engineering
                                activities.Communicating and organizing meetings with key business stakeholders at every
                                level.Acting as a subject matter expert on data & analytics.
                                Staying up to date on new technologies and data capabilities.Identifying new areas of
                                influence for the Data & Analytics division.</p>

                            <ul>
                                <li>
                                    Project Management Dashboard was able to save 17 man-hr each week.
                                </li>
                                <li>Created live Twitter sentiment analysis to harvest social media data.
                                </li>
                                <li>By Creating an Automated Visualisation dashboard company able to save 8 man hours
                                    per day.
                                </li>
                            </ul>
                        </section>

                        <section className="experience">
                            <h2>Programmer</h2>
                            <h3>Blue Hills IT Service - BD</h3>
                            <p>October 2014 - July 2017</p>
                            <p>My key responsibility was to create a fully automated project management dashboard for
                                multiple steak holder.
                                Collaborate with different Data scientists to understand their needs from ETL to Data
                                visualization. Designing data pipelines, models, and visualizations that allow the
                                organization to understand its, business drivers.Performing cloud-based data engineering
                                activities.Communicating and organizing meetings with key business stakeholders at every
                                level.Acting as a subject matter expert on data & analytics.
                                Staying up to date on new technologies and data capabilities.Identifying new areas of
                                influence for the Data & Analytics division.</p>

                            <ul>
                                <li>
                                    Software back-end developed using Django
                                </li>
                                <li> Portfolio website development using bootstrap
                                </li>
                                <li>Shared server Maintains
                                </li>
                            </ul>
                        </section>


                        {/* ... other sections like skills, education, etc. ... */}

                    </div>
                </div>
            </div>


            <footer className="footer">
                Made with <span role="img" aria-label="love">❤️</span>
            </footer>
        </div>

    );
}

export default MainComponent;