import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Maidha from './Maidah'; // Import the Maidha component
import Maliha from "./Maliha";
import Maidah from "./Maidah"
import MainComponent from "./MainComponent";
import './css/App.css';
function App() {
    // Replace with your actual data and import images as needed
    const profileData = {
        avatarUrl: process.env.PUBLIC_URL + '/IMGlow.jpg',
        name: "Zahid Uan Nabi",
        tagline: "Data Science & Big Data Analysis Enthusiast",
        bio: "Armed with a Bachelor's Degree in Computer Science and Engineering, I command expertise across Python, SQL, and cutting-edge analytics tools like Tableau, DAQ, D3 Notebook & Sagemaker. My realm extends from Backend Development to pioneering roles in Data Engineering, Data Mining, Predictive Analytics, Data Visualization, and Machine Learning. This isn't just proficiency; it's a robust command, a transformative capacity to morph complex data sets into striking narratives and decisive strategies. My background isn't just a skill set, but a testament to a powerhouse capable of steering the digital narrative through any challenge!",
        // ... additional profile data
    };
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={MainComponent} />
                <Route path="/maidah" component={Maidah} />
                <Route path="/maliha" component={Maliha} />
            </Switch>
        </Router>

    );


}

export default App;


