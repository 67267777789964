import React from 'react';

const Maidah = () => {
    return (
        <div>
            <h1>Hello Maidah 😉 </h1>
            {/* Content of the Maidha page goes here */}
        </div>
    );
};

export default Maidah;
